@import 'variablescss';

* {
  margin: 0;
  padding: 0;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  box-sizing: border-box;
  background-color: $general-bg;
  min-height: 100vh;
  font-size: 1rem;
  font-family: 'HNCondensedLight';

  b {
    font-family: 'HNCondensedMedium';
  }
}

a {
  color: inherit;

  &.disabled:hover {
    text-decoration: none;
    color: inherit;
    cursor: default;
  }
}

#header-btns {
  float: right;
  margin-top: -0.7rem;
  position: relative;
  z-index: 100;
}

body.app {
  .toast-top-center {
    top: 40px !important;
  }
}

@media (max-width: 767px) {
  #header-btns {
    margin-top: 0px !important;
    .button-secondary {
      font-size: 0;
      padding: 0.3rem 0.7rem 0.3rem;

      i {
        margin: 0;
      }
    }
  }
}

google-map {
  .cluster {
    div {
      line-height: 40px !important;
      width: 46px !important;
      font-size: 11px;
      font-family: Arial, sans-serif;
      font-weight: bold;
    }
  }
}
