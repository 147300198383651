.card {
  border-radius: 0;
  background-color: $card-body-bg;
  box-shadow: $box-shadow;

  &-header {
    border-radius: 0px !important;
    padding: 1.5rem;
    position: relative;

    & > button {
      position: absolute;
      right: 1.5rem;
      top: 50%;
      transform: translateY(-55%);
    }

    & > .button-box {
      position: absolute;
      right: 1.5rem;
      top: 50%;
      transform: translateY(-55%);
    }
  }

  &-filters {
    display: flex;
    align-items: center;
  }

  &-body {
    padding: 1.5rem;

    ul.card-list {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        border-bottom: 1px solid $light-gray;
        padding: 1rem 0 0.7rem;
        display: flex;

        span:first-child {
          width: 220px;
        }
        span:last-child {
          font-family: 'HNCondensedMedium';
        }
      }
    }

    ul.info-list {
      margin: 0;
      list-style: none;
      color: $dark-gray;

      li {
        padding: 0.9rem 0 0.6rem;
        border-bottom: 1px solid $gray;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        font-family: 'HNCondensedLight';

        & > span:first-child {
          width: 34%;
        }

        & > span:nth-child(2) {
          font-family: 'HNCondensedMedium';
          width: 60%;

          .button-secondary {
            position: absolute !important;
            right: 6px;
            top: 8px;
          }
          .link-primary {
            position: absolute !important;
            right: 6px;
            top: 14px;
          }
        }

        ng-select {
          width: 60%;
        }

        .note {
          position: absolute;
          right: 0;
          top: 0.8rem;
        }
      }
    }
  }

  &-footer {
    background-color: $white;
    text-align: right;
  }
}

.card-small {
  @extend .card;

  .card-header {
    border-bottom: none;
    padding: 1rem;
    background-color: transparent !important;

    justify-content: space-between;
    align-items: center;
    display: flex;
  }
  background-color: $card-small-bg;
}

.card-table {
  @extend .card;

  .card-header {
    border-bottom: none;

    h2 {
      max-width: 75%;
    }
  }

  .card-body {
    padding: 0rem !important;
  }

  .card-footer {
    border-top: none;
  }
}

@media (max-width: 767px) {
  .card-filters {
    display: flex;
    flex-basis: 100%;
    flex-flow: row;
  }
  .card-header {
    h2 > * {
      vertical-align: sub;
    }
    & button.button-secondary {
      font-size: 0;
      padding: 0.3rem 0.7rem 0.3rem;

      i {
        margin: 0;
      }
    }
  }
}
