$light-gray: #e6e6e6;
$gray: #dbdbdb;
$medium-gray: #a1a1a1;
$secondary-gray: #616161;
$dark-gray: #3c3c3c;
$black: #000000;
$green: #009640;
$dark-green: #005c27;
$white: #ffffff;
$orange: #ee721f;
$red: #cd1a19;
$yellow: #efad2e;

$primary: #3366cc;
$secondary: #616161;
$mobile-card: #f3f3f3;

$text-color: #3c3c3c;
$general-bg: #f2f2f2;
$header-bg: $white;
$navigation-bg: #ffffff;
$navigation-border-color: $primary;

$table-header-bg: $primary;
$table-header-color: $white;
$box-shadow: 0px 4px 8px -3px rgb(138 138 138 / 75%);
$table-body-bg: $white;
$card-body-bg: $white;
$card-small-bg: $white;
$btn-border-radius: 0px;
