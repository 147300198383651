@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');
@import 'variablescss';
@import 'bootstrap';
@import 'scss/abstract/fontface';
@import 'base';
@import 'buttons';
@import 'table';
@import 'typography';
@import 'cards';
@import 'modals';
@import 'forms';
@import 'toast';
@import '@ng-select/ng-select/themes/default.theme.css';
