@font-face {
  font-family: 'HNCondensed';
  font-weight: 400;
  line-height: normal;
  src: url('../../../src/assets/fonts/HelveticaNeueLTW1G-Cn.otf');
}

@font-face {
  font-family: 'HNCondensedLight';
  font-weight: 200;
  line-height: normal;
  src: url('../../../src/assets/fonts/HelveticaNeueLTW1G-LtCn.otf');
}

@font-face {
  font-family: 'HNCondensedMedium';
  font-weight: 600;
  line-height: normal;
  src: url('../../../src/assets/fonts/HelveticaNeueLTW1G-MdCn.otf');
}

@font-face {
  font-family: 'HNCondensedBold';
  font-weight: 800;
  line-height: normal;
  src: url('../../../src/assets/fonts/HelveticaNeueLTW1G-BdCn.otf');
}

@font-face {
  font-family: 'HNBold';
  font-weight: 800;
  line-height: normal;
  src: url('../../../src/assets/fonts/HelveticaNeueLTW1G-Bd.otf');
}
