.modal-content {
  border-radius: 0;
  margin-bottom: 70px;
  margin-top: 40px;

  .modal-header {
    padding: 1.5rem 1.5rem 1rem;
  }

  .modal-body {
    padding: 1.5rem;
  }

  .modal-footer {
    border: none;
    padding: 1.5rem 1.5rem 1rem;
  }

  hr {
    margin: 2rem -1.5rem;
  }

  hr.regular {
    margin: 1rem 0;
  }
}

.modal-lg {
  max-width: 670px;
}

modal-container {
  margin-top: env(safe-area-inset-top);
}
