.text-primary {
  color: $primary !important;
}

.text-secondary {
  color: $secondary !important;
}

.text-link-primary {
  font-family: 'HNCondensedMedium';
  text-transform: uppercase;

  font-size: 1rem;
  color: $primary !important;
}

h1 {
  color: $primary;
  font-family: 'HNCondensedLight';
  font-size: 2rem;
  text-transform: uppercase;
  border-bottom: 1px solid $primary;
  position: relative;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  z-index: 0;

  &:after {
    content: '';
    width: 210px;
    height: 3px;
    background-color: $primary;
    position: absolute;
    bottom: 0px;
    left: 0;
  }
}

h2 {
  font-family: 'HNCondensedLight';
  color: $secondary;
  font-size: 1.3rem;
  text-transform: uppercase;
  margin-bottom: 0;
}

h3 {
  font-family: 'HNCondensedLight';
  color: $medium-gray;
  font-size: 1.1rem;
  text-transform: uppercase;
  margin-bottom: 0;
}

h4 {
  font-family: 'HNCondensedMedium';
  color: $secondary;
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 0;
}

h5 {
  font-family: 'HNCondensedLight';
  color: $medium-gray;
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 0;
}

.custom-label {
  font-family: 'HNCondensedLight';
  color: $secondary;
  font-size: 0.9rem;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0;
  margin-top: 1.5rem;
}

.custom-badge {
  padding: 0.25rem;
  font-size: 0.7rem;
  color: white;
  border-radius: 50%;

  &:not(.check) {
    margin-right: 5px;
  }

  &.signal-wifi-off {
    background-color: $orange;
  }

  &.warning,
  &.error-outline {
    background-color: $red;
  }
  &.update {
    background-color: $yellow;
  }
  &.settings-input-antenna,
  &.all,
  &.normal {
    background-color: $medium-gray;
  }
  &.check {
    background-color: transparent;
    color: $dark-green;
    font-size: 0.95rem;
    padding: 0 0.25rem;
    vertical-align: middle;
  }
  &.bl {
    background-color: $blue;
  }
  &.warning-severity {
    background-color: $orange;
  }
  &.alarm-severity {
    background-color: $yellow;
  }
  &.ok-severity,
  &.swap-horiz,
  &.success {
    background-color: $dark-green;
  }
  &.evacuation-severity,
  &.error-severity {
    background-color: $red;
  }
}

.custom-badge-xs {
  @extend .custom-badge;
  padding: 0.25rem;
  font-size: 0.6rem;

  &.swap-horiz,
  &.check {
    background-color: $dark-green;
    color: $white;
    font-size: 0.6rem;
    padding: 0.25rem !important;
  }
}

.custom-badge-md {
  @extend .custom-badge;

  padding: 0.4rem;
  font-size: 1.4rem;

  &.swap-horiz,
  &.check {
    background-color: $dark-green;
    color: $white;
    font-size: 1.5rem;
    padding: 0.4rem !important;
  }
}

.custom-badge-lg {
  @extend .custom-badge;
  font-size: 2.1rem !important;
  padding: 0.5rem !important;
  margin-right: 1rem !important;

  &.swap-horiz,
  &.check,
  &.ok-severity,
  &.normal {
    background-color: $dark-green;
    color: $white;
  }
}

.icon-image {
  height: 0.9rem;
  margin-right: 0.6rem;
  vertical-align: text-top;
}

.custom-icon {
  padding: 0.25rem;
  font-size: 0.7rem;
  color: white;
  border-radius: 50%;

  &.signal-wifi-off {
    background-color: $orange;
  }
  &.warning,
  &.error-outline {
    background-color: $red;
  }
  &.update,
  &.yellow {
    background-color: $yellow;
  }
  &.settings-input-antenna {
    background-color: $medium-gray;
  }
  &.swap-horiz,
  &.check {
    background-color: $dark-green;
  }

  &.big {
    background-color: rgb(0, 0, 0, 0.06);
    padding: 0.6rem;
    font-size: 1.7rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    border-radius: 50%;

    &.signal-wifi-off {
      color: $orange;
    }
    &.warning,
    &.error-outline {
      color: $red;
    }
    &.update,
    &.yellow {
      color: $yellow;
    }
    &.settings-input-antenna {
      color: $medium-gray;
    }
    &.swap-horiz,
    &.check {
      color: $dark-green;
    }
  }
}

@media (max-width: 767px) {
  h1 {
    word-break: break-all;
  }
}
