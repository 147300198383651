ngx-datatable {
  overflow: visible !important;

  .datatable-group-header {
    border-bottom: 0 !important;
    padding: 0.5rem 1rem;

    .datatable-header-internal {
      text-decoration: none;
      color: $primary;
    }
  }

  .datatable-header {
    height: auto !important;

    .datatable-header-cell {
      white-space: normal !important;
      padding: 0.6rem 1.2rem 0.2rem !important;
      text-transform: uppercase;
      font-size: 0.9rem !important;
    }
  }

  .datatable-body {
    overflow: auto !important;
    z-index: 10;
  }

  .datatable-body-row {
    height: auto !important;
    font-size: 0.9rem !important;

    &.disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }

    .datatable-body-cell {
      display: flex !important;
      align-items: center;
      border-bottom: 1px solid $light-gray;

      overflow: visible !important;
      line-height: 1rem;
      min-height: 60px;
      overflow-wrap: anywhere !important;
      word-break: break-word !important;
      white-space: normal !important;
      &.flex {
        .datatable-body-cell-label {
          display: flex;
        }
      }
      .datatable-body-cell-label > * {
        vertical-align: text-top;
      }
    }
  }
}

datatable-scroller.datatable-scroll {
  max-width: 100% !important;
}

.favorite-icon {
  font-size: 1.1rem;
  color: $dark-gray;
  cursor: pointer;
}

.menu-icon {
  font-size: 1.1rem;
  cursor: pointer;
}

.single-menu-icon {
  border: 1px solid;
  border-radius: 50%;
  padding: 0.3rem;
  font-size: 1.2rem;
  cursor: pointer;

  &:hover {
    background-color: $dark-gray;
    border-color: $dark-gray;
    color: $white;
  }

  &.text-primary:hover {
    background-color: $dark-green;
    border-color: $dark-green;
    color: $white !important;
  }

  &:active {
    background-color: $light-gray;
    border-color: $light-gray;
    color: $dark-gray;
  }

  &.disabled {
    background-color: transparent;
    border-color: transparent;
    color: $light-gray;
  }
}

.dropdown-menu {
  border-radius: 0;
  box-shadow: 0px 0px 6px $gray;
  border: none;
  z-index: 1000000;

  li {
    cursor: pointer;

    & a.disabled {
      color: $gray;
    }
  }
}

@media (max-width: 767px) {
  ngx-datatable {
    .datatable-body {
      overflow: auto !important;
      z-index: 10;
    }
  }
}

table {
  width: 100%;
  thead {
    text-transform: uppercase;
    font-size: 0.9rem !important;
    background-color: $table-header-bg;
    color: $table-header-color;

    th {
      padding: 0.6rem 1.2rem 0.2rem !important;
    }
  }

  tbody {
    tr {
      height: auto !important;
      font-size: 0.9rem !important;
      border-bottom: 1px solid $light-gray;

      &:last-child {
        border-bottom: none;
      }

      td {
        padding: 0.8rem 1rem;
      }
    }
  }
}

.pagination {
  justify-content: flex-end;
  padding: 1rem 1rem 0;
}
.page-item {
  .page-link {
    padding-bottom: 0.3rem;
    background-color: transparent !important;
    border: none;
    color: $dark-gray;
  }

  &.active {
    .page-link {
      background-color: $primary !important;
    }
  }

  &.disabled {
    .page-link {
      color: $gray !important;
    }
  }
}

.mobile-table {
  max-height: calc(100vh - 15rem);
  overflow-y: auto;
  padding: 1rem;
}

.mobile-card {
  border: 1px solid $mobile-card;
  border-radius: 10px;
  background-color: $mobile-card;
  padding: 1rem;
  position: relative;
  margin-bottom: 1rem;
  box-shadow: 0px 3px 6px #0000002b;

  &-checkbox {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
  &-subtitle {
    display: block;
    font-size: 0.95rem;
    font-family: 'HNCondensedLight';
  }

  &-title {
    font-size: 1.2rem;
  }

  &-note {
    font-size: 0.9rem;
    color: $medium-gray;
    text-transform: uppercase;
    font-family: 'HNCondensedLight';
    & b {
      font-family: 'HNCondensedLight' !important;
    }
  }

  &-icons {
    margin-top: 3px;
    margin-bottom: -5px;
    display: block;

    & > * {
      font-size: 0.92rem;
      margin-right: 10px;
    }
  }

  &-actions {
    position: absolute;
    right: 0.7rem;
    bottom: 0.5rem;

    i {
      font-size: 1.1rem;
      &:last-child {
        margin-right: 0px !important;
      }
    }
  }

  .btn-group {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);

    &.top {
      right: 1rem;
      top: 1rem;
      transform: none;
    }
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
