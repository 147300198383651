@import 'variablescss';

$ngx-datatable-background: $white;
$ngx-datatable-box-shadow: none;
$ngx-datatable-row-odd-background: $white;

// default row and cell background colors
$ngx-datatable-default-background: $white;
$ngx-datatable-default-background-hover: $light-gray;
$ngx-datatable-default-background-focus: $light-gray;

// background and text colors for selected cell / row
$ngx-datatable-selected-active-background: $light-gray;
$ngx-datatable-selected-active-background-hover: $light-gray;
$ngx-datatable-selected-active-background-focus: $light-gray;

// colors for header elements
$datatable-header-cell-background: rgba($table-header-bg, 1);
$datatable-header-cell-color: $table-header-color;
$datatable-header-border-bottom-color: $gray;
$datatable-header-resize-handle-color: $light-gray;

// colors for table body elements
$datatable-body-cell-color: $dark-gray;
$datatable-group-header-border-bottom-color: $primary;

.ngx-datatable.material {
  background: $white;
  box-shadow: none;

  &.striped {
    .datatable-row-odd {
      background: $white;
    }
  }

  &:not(.cell-selection) {
    .datatable-body-row {
      &:hover,
      &:hover .datatable-row-group {
        background-color: $light-gray;
      }

      &:focus,
      &:focus .datatable-row-group {
        background-color: $light-gray;
      }
    }
  }

  &.single-selection,
  &.multi-selection,
  &.multi-click-selection {
    .datatable-body-row {
      &.active,
      &.active .datatable-row-group {
        background-color: $light-gray;
      }

      &.active:hover,
      &.active:hover .datatable-row-group {
        background-color: $light-gray;
      }

      &.active:focus,
      &.active:focus .datatable-row-group {
        background-color: $light-gray;
      }
    }
  }

  .datatable-header {
    border-bottom: 1px solid $gray;

    .datatable-header-cell {
      background-color: rgba($table-header-bg, 1);
      color: $table-header-color;
    }

    .resize-handle {
      border-right: solid 1px $light-gray;
    }
  }

  .datatable-body {
    .datatable-group-header {
      border-bottom: solid 1px $primary;
    }
    .datatable-body-row {
      .datatable-body-cell {
        color: $dark-gray;
      }
    }
  }

  .datatable-header,
  .datatable-body {
    .datatable-row-left {
      background-color: $white;
    }

    .datatable-row-right {
      background-color: $white;
    }
  }
}
