@import 'ngx-toastr/toastr';

.toast {
  &-success {
    @extend .toast;
    background-color: $green;
    background-image: none;
  }
  &-error {
    @extend .toast;
    background-image: none;
    background-color: $red;
  }
}

.toast-container .ngx-toastr {
  padding: 0rem;
  margin-top: 1rem;
}

.toast-title {
  padding: 0.6rem 1rem;
  font-family: 'HNCondensedMedium';
  font-size: 1.1rem;
}

.toast-message {
  padding: 0rem 1rem 0.6rem;
  font-family: 'HNCondensedLight';
  font-size: 1rem;
}
